<template>
    <v-chip
        class="table-label"
        small
        label
        dark
        :color="statusColor"
    >
        <div>{{$t(text)}}</div>
    </v-chip>
</template>

<script>
export default {
    props: {
        type: {
            type: Number,
            default: -1
        },
    },
    computed:
    {
        text(){
            switch (this.type)
            {
                case 0:
                    return "Решение";
                case 1:
                    return "Постановление";
                case 2:
                    return "Распоряжение";
                default:
                    return "NaN";
            }
        },
        statusColor()        {
            switch (this.type)
            {
                case 0:
                    return "#BE95C6";
                case 1:
                    return "#BE95C6";
                case 2:
                    return "#BE95C6";
                default:
                    return "#9D81BA";
            }
        }
    }
}
</script>